import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48')
];

export const server_loads = [];

export const dictionary = {
		"/": [~6],
		"/app": [7,[2]],
		"/app/event": [8,[2]],
		"/app/event/[id]": [9,[2]],
		"/app/event/[id]/coachold": [11,[2]],
		"/app/event/[id]/coach": [10,[2]],
		"/app/event/[id]/manage": [12,[2,3]],
		"/app/event/[id]/manage/athletes": [13,[2,3]],
		"/app/event/[id]/manage/athletes/addathlete": [15,[2,3]],
		"/app/event/[id]/manage/athletes/createathlete": [16,[2,3]],
		"/app/event/[id]/manage/athletes/[eventathleteid]": [14,[2,3]],
		"/app/event/[id]/manage/performance": [17,[2,3]],
		"/app/event/[id]/manage/setup": [18,[2,3]],
		"/app/event/[id]/manage/timeslot": [19,[2,3]],
		"/app/event/[id]/manage/timeslot/[classid]": [20,[2,3]],
		"/app/event/[id]/manage/timeslot/[classid]/[visitid]": [21,[2,3]],
		"/app/settings": [22,[2]],
		"/app/settings/setup/attributes": [23,[2,4]],
		"/app/settings/setup/attributes/[id]": [24,[2,4]],
		"/app/settings/setup/equipment": [25,[2,4]],
		"/app/settings/setup/equipment/[id]": [26,[2,4]],
		"/app/settings/setup/events": [27,[2,4]],
		"/app/settings/setup/events/import": [29,[2,4]],
		"/app/settings/setup/events/[id]": [28,[2,4]],
		"/app/settings/setup/sites": [30,[2,4]],
		"/app/settings/setup/sites/[id]": [31,[2,4]],
		"/app/settings/setup/tests": [32,[2,4]],
		"/app/settings/setup/tests/groups": [34,[2,4]],
		"/app/settings/setup/tests/groups/[testgroupid]": [35,[2,4]],
		"/app/settings/setup/tests/[id]": [33,[2,4]],
		"/app/settings/setup/units": [36,[2,4]],
		"/app/settings/setup/units/[id]": [37,[2,4]],
		"/app/settings/setup/users": [38,[2,4]],
		"/app/settings/setup/users/[siteid]/[id]": [39,[2,4]],
		"/dev": [40,[5]],
		"/dev/feedback": [41,[5]],
		"/dev/functions": [42,[5]],
		"/dev/load": [43,[5]],
		"/dev/load/loadall": [44,[5]],
		"/dev/neweventtables": [45,[5]],
		"/dev/policies": [46,[5]],
		"/dev/tabledata": [47,[5]],
		"/dev/tables": [48,[5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';